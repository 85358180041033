import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useProduct } from "../context/ProductContext";
import { Product, Tag } from "../models/Product";
import ProductCard from "../components/ProductCard";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { Producer } from "../models/Producer";
import { useProducer } from "../context/ProducerContext";
import { Link } from "react-router-dom";

function Home() {
  const { getProductsByTag } = useProduct();
  const [newProducts, setNewProducts] = React.useState<Product[]>([]);
  const [sale, setSale] = React.useState<Product[]>([]);
  const [bestsellers, setBestsellers] = React.useState<Product[]>([]);
  const [recommended, setRecommended] = React.useState<Product[]>([]);

  const [producers, setProducers] = React.useState<Producer[]>([]);
  const { getProducersWithImages } = useProducer();

  React.useEffect(() => {
    const fetchProducts = async () => {
      try {
        setNewProducts(await getProductsByTag(Tag.New));
        setSale(await getProductsByTag(Tag.Sale));
        setBestsellers(await getProductsByTag(Tag.Bestseller));
        setRecommended(await getProductsByTag(Tag.Recommended));
      } catch (e) {
        console.error(e);
      }
    };

    const fetchProducers = async () => {
      try {
        setProducers(await getProducersWithImages());
      } catch (e) {
        console.error(e);
      }
    };

    fetchProducers();
    fetchProducts();
  }, []);

  useEffect(() => {
    document.title = `Eltimex - Strona Główna`;
  }, []);

  return (
    <div className="flex flex-col justify-start divide-y">
      <div>
        {/* <div className="w-full h-0">
          <Swiper
            spaceBetween={30}
            slidesPerView={"auto"}
            centeredSlides={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, Navigation]}
            style={{ width: "100%", height: "384px" }}
          >
            <SwiperSlide>
              <img src={"https://placehold.co/1536x384"} alt="placeholder" />
            </SwiperSlide>
          </Swiper>
        </div> */}
        <div className="my-8 w-full flex flex-col items-center">
          <h2 className="text-3xl font-bold my-5 text-center uppercase">
            Katalog Online - Zapytaj o Ofertę
          </h2>
          <p className="text-lg text-center max-w-5xl">
            Zapraszamy do składania zapytań o dostępność i wyceny. Zawsze
            jesteśmy gotowi doradzić i przygotować ofertę dopasowaną do Twoich
            potrzeb. Wystarczy wybrać interesujące produkty, a następnie z
            panelu listy zakpowej złożyć zapytanie, a my odpowiemy w możliwie
            najkrótszym czasie.
          </p>
        </div>
      </div>
      {sale.length !== 0 && (
        <div id="wyprzedaz" className="mt-8 p-5 flex items-center space-x-1">
          <div className="w-10 h-64 flex items-center justify-center mr-2">
            <h2 className="-rotate-90 uppercase text-primary text-4xl font-bold">
              Wyprzedaż
            </h2>
          </div>
          <Swiper
            slidesPerView="auto"
            grabCursor={true}
            loop
            modules={[Autoplay, Pagination, Navigation]}
            style={{ width: "100%", height: "384px" }}
          >
            {sale?.map((product) => (
              <SwiperSlide
                key={product.id}
                style={{ width: "auto", height: "auto" }}
              >
                <ProductCard
                  product={product}
                  inDisplayMode={true}
                  horizontal={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {newProducts.length !== 0 && (
        <div className="p-5 flex items-center space-x-1" id="niedawno-dodane">
          <div className="w-10 h-64 flex items-center justify-center mr-2">
            <h2 className="-rotate-90 uppercase text-primary text-4xl font-bold">
              Nowe
            </h2>
          </div>
          <Swiper
            slidesPerView="auto"
            grabCursor={true}
            loop
            modules={[Autoplay, Pagination, Navigation]}
            style={{ width: "100%", height: "384px" }}
          >
            {newProducts?.map((product) => (
              <SwiperSlide
                key={product.id}
                style={{ width: "auto", height: "auto" }}
              >
                <ProductCard
                  product={product}
                  inDisplayMode={true}
                  horizontal={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {bestsellers.length !== 0 && (
        <div className="p-5 flex items-center space-x-1" id="bestsellery">
          <div className="w-10 h-64 flex items-center justify-center mr-2">
            <h2 className="-rotate-90 uppercase text-primary text-4xl font-bold">
              Bestsellery
            </h2>
          </div>
          <Swiper
            slidesPerView="auto"
            grabCursor={true}
            loop
            modules={[Autoplay, Pagination, Navigation]}
            style={{ width: "100%", height: "384px" }}
          >
            {bestsellers?.map((product) => (
              <SwiperSlide
                key={product.id}
                style={{ width: "auto", height: "auto" }}
              >
                <ProductCard
                  product={product}
                  inDisplayMode={true}
                  horizontal={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {recommended.length !== 0 && (
        <div className="p-5 flex items-center space-x-1" id="polecane">
          <div className="w-10 h-64 flex items-center justify-center mr-2">
            <h2 className="-rotate-90 uppercase text-primary text-4xl font-bold">
              Polecane
            </h2>
          </div>
          <Swiper
            slidesPerView="auto"
            grabCursor={true}
            loop
            modules={[Autoplay, Pagination, Navigation]}
            style={{ width: "100%", height: "384px" }}
          >
            {recommended?.map((product) => (
              <SwiperSlide
                key={product.id}
                style={{ width: "auto", height: "auto" }}
              >
                <ProductCard
                  product={product}
                  inDisplayMode={true}
                  horizontal={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <div className="my-10 w-full flex flex-col items-center">
        <h1 className="text-5xl font-bold my-5 text-center uppercase max-w-">
          <span className="text-secondary ">Eltimex</span> -{" "}
          <span className="">Hurtownia Elektryczna z Tradycją</span>
        </h1>
        <p className="text-lg text-center max-w-5xl">
          Jesteśmy hurtownią elektryczną z wieloletnim doświadczeniem, która
          stawia na jakość, niezawodność i profesjonalną obsługę. Nasz katalog
          obejmuje szeroki asortyment produktów elektrycznych - od kabli,
          przewodów, osprzętu instalacyjnego, po oświetlenie i nowoczesne
          technologie.
        </p>
      </div>
      {producers.length !== 0 && (
        <div className="p-5">
          <div className="overflow-hidden w-full h-32 flex items-center justify-around overflow-x-scroll">
            {producers.map((producer) => (
              <Link to={`/producent/${producer.name}`} key={producer.id}>
                <img
                  src={producer.image!}
                  alt={producer.name}
                  height={125}
                  width={125}
                  className="object-contain m-2 grayscale hover:grayscale-0"
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
