import { SearchOutlined } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useDebounce from "../hooks/useDebounce";
import { useProduct } from "../context/ProductContext";
import { Product } from "../models/Product";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

function SearchBar() {
  const width = window.innerWidth;

  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get("search") || "";

  const navigate = useNavigate();

  const { control } = useForm();
  const { getProducts } = useProduct();
  const searchBarRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState<string>(initialSearch);
  const [productList, setProductList] = useState<Product[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const debouncedQuery = useDebounce(search, 500);

  const getProductsData = async (page: number, query: string) => {
    const response = await getProducts(page, 5, query);
    setProductList(response.products);
  };

  useEffect(() => {
    if (debouncedQuery !== "") {
      getProductsData(1, debouncedQuery);
    } else {
      setProductList([]);
    }
  }, [debouncedQuery]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={searchBarRef}
      className="flex flex-col items-center space-y-1 w-full max-w-[600px]"
    >
      <div className="flex items-center justify-center space-x-1 relative w-full">
        <form
          name="search"
          id="search"
          className="flex items-center w-full"
          onSubmit={(e) => {
            e.preventDefault();
            navigate(`/szukaj?search=${search}`);
          }}
        >
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                value={search}
                onFocus={() => setIsFocused(true)}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Znajdź produkt..."
                className="border border-gray-300 rounded-sm p-2 w-full max-w-[500px] h-full hover:drop-shadow-xl hover:border-primary focus:outline-none focus-within:border-primary focus-within:drop-shadow-xl"
              />
            )}
          />
          <IconButton color="primary" type="submit" form="search">
            <SearchOutlined sx={{ fontSize: width <= 768 ? 25 : 40 }} />
          </IconButton>
        </form>
        {isFocused && productList.length > 0 && (
          <div className="border border-gray-300 rounded-sm w-full mt-2 bg-white drop-shadow-lg absolute top-full z-10">
            <p className="p-2 text-sm text-gray-500">Wyniki wyszukiwania:</p>
            <ul>
              {productList.map((product, index) => (
                <li
                  key={index}
                  className="p-2 border-b last:border-b-0 hover:bg-gray-100"
                >
                  <Link
                    to={`/produkt/${product.slug}`}
                    onClick={() => setIsFocused(false)}
                    className="w-full h-full"
                  >
                    <SearchOutlined />
                    <span className="ml-2">{product.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
