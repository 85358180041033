import React, { createContext, useContext, ReactNode } from "react";
import axios from "axios";
import { withAuth } from "../middleware/withAuth";
import { Product, Tag } from "../models/Product";
import { Category } from "../models/Category";

interface ProductContextType {
  createProduct: (formData: any) => Promise<void>;
  editProduct: (
    id: number,
    deletedSecondaryImages: number[],
    formData: FormData
  ) => Promise<void>;
  getProducts: (
    page?: number,
    limit?: number,
    query?: string,
    categoryId?: number | null,
    minPrice?: number,
    maxPrice?: number,
    filters?: Record<number, string[]>,
    producers?: string[]
  ) => Promise<{
    products: Product[];
    totalProducts: number;
    totalPages: number;
    currentPage: number;
    maxPrice: number;
    minPrice: number;
  }>;
  getProduct: (id: number) => Promise<{
    product: Product;
    categoryAncestry: Category[];
  }>;
  getProductByName: (name: string) => Promise<{
    product: Product;
    categoryAncestry: Category[];
  }>;
  deleteProduct: (id: number) => Promise<void>;
  getPopularProducts: () => Promise<Product[]>;
  getProductsByTag: (tag: Tag) => Promise<Product[]>;
  getProductsForProducer: (name: string) => Promise<Product[]>;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const useProduct = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProduct must be used within a ProductProvider");
  }
  return context;
};

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const deleteProduct = async (id: number) => {
    try {
      const config = await withAuth({
        method: "delete",
        url: `${apiUrl}/api/products/product/${id}`,
      });
      await axios(config);
      await getProducts();
    } catch (error: any) {
      console.error("Failed to delete Product", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProductsForProducer = async (name: string): Promise<Product[]> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/products/producer/${encodeURI(name)}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Products for Producer", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getPopularProducts = async (): Promise<Product[]> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/products/popular`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Popular Products", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProductsByTag = async (tag: Tag): Promise<Product[]> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/products/tag`,
        params: {
          tag,
        },
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Popular Products", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProducts = async (
    page?: number,
    limit?: number,
    query?: string,
    categoryId?: number | null,
    minPrice?: number,
    maxPrice?: number,
    filters?: Record<number, string[]>,
    producers?: string[]
  ): Promise<{
    products: Product[];
    totalProducts: number;
    totalPages: number;
    currentPage: number;
    maxPrice: number;
    minPrice: number;
  }> => {
    try {
      const serializedFilters = JSON.stringify(filters);
      const serializedProducers = JSON.stringify(producers);

      const config = {
        method: "get",
        url: `${apiUrl}/api/products`,
        params: {
          page,
          limit,
          search: query,
          categoryId,
          minPrice,
          maxPrice,
          filters: serializedFilters,
          producers: serializedProducers,
        },
      };

      const response = await axios(config);
      return {
        products: response.data.products,
        totalProducts: response.data.totalProducts,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage || 1,
        maxPrice: response.data.maxPrice,
        minPrice: response.data.minPrice,
      };
    } catch (error: any) {
      console.error("Failed to fetch Products", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProduct = async (
    id: number
  ): Promise<{
    product: Product;
    categoryAncestry: Category[];
  }> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/products/product/${id}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Product", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const getProductByName = async (
    name: string
  ): Promise<{
    product: Product;
    categoryAncestry: Category[];
  }> => {
    try {
      const config = {
        method: "get",
        url: `${apiUrl}/api/products/product/name/${name}`,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      console.error("Failed to fetch Product", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const createProduct = async (formData: FormData) => {
    try {
      const config = await withAuth({
        method: "post",
        url: `${apiUrl}/api/products/product`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });
      await axios(config);
      await getProducts();
    } catch (error: any) {
      console.error("Failed to create Product", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  const editProduct = async (
    id: number,
    deletedSecondaryImages: number[],
    formData: FormData
  ) => {
    try {
      const config = await withAuth({
        method: "put",
        url: `${apiUrl}/api/Products/Product/${id}`,
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          ...Object.fromEntries(formData.entries()),
          deletedSecondaryImages,
        },
      });
      await axios(config);
      await getProducts();
    } catch (error: any) {
      console.error("Failed to edit Product", error);
      throw new Error(error.response?.data?.message || "Server error");
    }
  };

  return (
    <ProductContext.Provider
      value={{
        createProduct,
        getProductsForProducer,
        getProductByName,
        getProducts,
        getProduct,
        deleteProduct,
        editProduct,
        getProductsByTag,
        getPopularProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
