import { Category } from "./Category";
import { ParameterValue } from "./Parameter";
import { Producer } from "./Producer";

export type Product = {
  id: number;
  name: string;
  slug: string;
  serialNumber: string;
  categoryId: number;
  producerId: number;
  description?: string;
  price: number;
  primaryImage: string;
  mobilePrimaryImage: string;
  keywords?: string;
  popularity: number;
  tag?: Tag | null;

  parameterValues: ParameterValue[];
  secondaryImages: SecondaryImage[];
  producer: Producer;
  category: Category;
};

export enum Tag {
  New = "New",
  Sale = "Sale",
  Bestseller = "Bestseller",
  Recommended = "Recommended",
}

export type SecondaryImage = {
  id: number;
  image: string;
  productId: number;
  order: number;
};
